<template>
    <div class="px-2 px-lg-3">
        <span class="text-uppercase fw-bolder fs-7">Investigación</span>
        <div class="w-100 ps-0 ps-lg-4">
            <p>
                <span class="section-label">> Ed. Básica</span>
                <span class="section-label px-2">|</span>
                <span class="section-title">{{ data.investigacion.basica.titulo || '---' }}</span>
                <span v-if="data.investigacion.basica.titulo && data.investigacion.basica.titulo.length > tooltipWidth" 
                    class="section-info-icon"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    :title="data.investigacion.basica.titulo"
                >...</span>
                <div class="float-end text-color-blue section-menu">
                    <a :class="!data.investigacion.basica.infografia ? 'disabled' : ''"
                        :href="!data.investigacion.basica.infografia ? '#noData' : data.investigacion.basica.infografia"
                        :target="!data?.investigacion.basica.infografia ? '_self' : '_blank'"
                    >Resumen</a>
                    <span class="px-1">|</span>
                    <a :class="!data.investigacion.basica.video ? 'disabled' : ''"
                        @click.prevent="onShowVideo(data.investigacion.basica.video)"
                        href="#" 
                    >Video</a>
                </div>
            </p>
        </div>
        <div class="w-100 ps-0 ps-lg-4" style="margin-top: -15px;">
            <p>
                <span class="section-label">> Ed. Media</span>
                <span class="section-label px-2">|</span>
                <span class="section-title">{{ data.investigacion.media.titulo || '---' }}</span>
                <span v-if="data.investigacion.media.titulo && data.investigacion.media.titulo.length > tooltipWidth"
                    class="section-info-icon"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    :title="data.investigacion.media.titulo"
                >...</span>
                <div class="float-end text-color-blue section-menu">
                    <a :class="!data.investigacion.media.infografia ? 'disabled' : ''"
                        :href="!data.investigacion.media.infografia ? '#noData' : data.investigacion.media.infografia"
                        :target="!data.investigacion.media.infografia ? '_self' : '_blank'"
                    >Resumen</a>
                    <span class="px-1">|</span>
                    <a :class="!data.investigacion.media.video ? 'disabled' : ''"
                        @click.prevent="onShowVideo(data.investigacion.media.video)"
                        href="#" 
                    >Video</a>
                </div>
            </p>
        </div>
    </div>
    <hr class="px-3 my-3">
    <div class="px-2 px-lg-3">
        <span class="text-uppercase fw-bolder fs-7">Innovación</span>
        <div class="w-100 ps-0 ps-lg-4">
            <p>
                <span class="section-label">> Ed. Básica</span>
                <span class="section-label px-2">|</span>
                <span class="section-title">{{ data.innovacion.basica.titulo || '---' }}</span>
                <span v-if="data.innovacion.basica.titulo && data.innovacion.basica.titulo.length > tooltipWidth"
                    class="section-info-icon"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    :title="data.innovacion.basica.titulo"
                >...</span>
                <div class="float-end text-color-blue section-menu">
                    <a :class="!data.innovacion.basica.infografia ? 'disabled' : ''"
                        :href="!data.innovacion.basica.infografia ? '#noData' : data.innovacion.basica.infografia"
                        :target="!data.innovacion.basica.infografia ? '_self' : '_blank'"
                    >Resumen</a>
                    <span class="px-1">|</span>
                    <a :class="!data.innovacion.basica.video ? 'disabled' : ''"
                        @click.prevent="onShowVideo(data.innovacion.basica.video)"
                        href="#" 
                    >Video</a>
                </div>
            </p>
        </div>
        <div class="w-100 ps-0 ps-lg-4" style="margin-top: -15px;">
            <p>
                <span class="section-label">> Ed. Media</span>
                <span class="section-label px-2">|</span>
                <span class="section-title">{{ data.innovacion.media.titulo || '---' }}</span>
                <span v-if="data.innovacion.media.titulo && data.innovacion.media.titulo.length > tooltipWidth"
                    class="section-info-icon"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    :title="data.innovacion.media.titulo"
                >...</span>
                <div class="float-end text-color-blue section-menu">
                    <a :class="!data.innovacion.media.infografia ? 'disabled' : ''"
                        :href="!data.innovacion.media.infografia ? '#noData' : data.innovacion.media.infografia"
                        :target="!data.innovacion.media.infografia ? '_self' : '_blank'"
                    >Resumen</a>
                    <span class="px-1">|</span>
                    <a :class="!data.innovacion.media.video ? 'disabled' : ''"
                        href="#" 
                        @click.prevent="onShowVideo(data.innovacion.media.video)"
                    >Video</a>
                </div>
            </p>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { Tooltip } from '@/assets/bootstrap/bootstrap.bundle.min.js'

export default {
    name: 'CongresoAnteriorRegion',
    props: {
        data: {
            type: Object,
            default: () => null
        }
    },
    emits: ['show-video'],
    setup(props, {emit}) {
        const tooltipList = ref()
        const tooltipWidth = ref(window.innerWidth <= 768 ? 20 : 45)

        const onShowVideo = (url) => {
            emit('show-video', url)
        }

        const changeTooltipWidth = () => {
            tooltipWidth.value = window.innerWidth <= 768 ? 20 : 45
        }

        onMounted(() => {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            tooltipList.value = tooltipTriggerList.map((elem) => {
                return new Tooltip(elem)
            })

            window.addEventListener('resize', changeTooltipWidth)
        })

        onUnmounted(() => {
            window.removeEventListener('resize', changeTooltipWidth)
        })
        
        return {
            tooltipWidth,
            onShowVideo,
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-main {
    background-color: var(--color1);
}
.text-color-blue {
    color: var(--color2);
}
.section-label {
    font-size: 0.9em;
    font-weight: bolder;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media(max-width: 576px) {
        font-size: 0.7em;
    }
    @media(max-width: 768px) {
        font-size: 0.75em;
    }
}
.section-title {
    display: inline-block;
    font-size: 0.85em;
    max-width: 320px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "";
    @media(max-width: 576px) {
        font-size: 0.6em;
        max-width: 80px; 
    }
    @media(max-width: 768px) {
        font-size: 0.7em;
        max-width: 80px; 
    }
}
.section-info-icon {
    font-size: 0.93em;
    font-weight: bolder;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 2px;
    @media(max-width: 576px) {
        font-size: 0.73em;
    }
    @media(max-width: 768px) {
        font-size: 0.83em;
    }
}
.section-menu {
    font-size: 0.9em;
    @media(max-width: 576px) {
        font-size: 0.7em;
    }
    @media(max-width: 768px) {
        font-size: 0.75em;
    }
}
a.disabled {
    color: #7e7e7e;
    cursor: auto;
}
</style>