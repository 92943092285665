<template>
    <mnSup mostrar="true" />
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-3 d-none d-lg-block px-0 text-center">
                <img src="/assets/img/congreso-anterior-logo.svg" 
                    class="img-fluid" 
                    style="width: 70%;"
                />
            </div>
            <div class="col-12 col-lg-6">
                <p class="px-2 px-lg-5">
                    <span class="text-uppercase fw-bolder fs-title-1">
                        XX Congreso Nacional Explora
                    </span>
                    <span class="fw-bolder fs-title-2">
                        Año 2022
                    </span>
                </p>
                <div class="w-100 mt-3 mt-lg-5">
                    <template v-if="regions.length > 0">
                        <span class="fw-bolder text-color-blue px-2 px-lg-5 text-uppercase fs-title-3">
                            Feria de Proyectos por Región
                        </span>
                        <div id="regionsAccordion" class="accordion accordion-flush mt-3 mt-lg-4 px-0 px-lg-4">
                            <div v-for="(region, i) in regions" :key="i" class="accordion-item mb-2 mb-lg-3 bg-main border-0">
                                <div :id="`flush-heading${i}`" class="accordion-header">
                                    <button type="button" 
                                        class="accordion-button collapsed rounded-pill fw-bold text-uppercase py-2 text-color-blue"
                                        data-bs-toggle="collapse" 
                                        :data-bs-target="`#flush-collapse${i}`" 
                                        aria-expanded="false" 
                                        :aria-controls="`flush-collapse${i}`"
                                    >
                                        <span class="accordion-button-label">
                                            {{ region.nombre }}
                                        </span>
                                    </button>
                                </div>
                                <div :id="`flush-collapse${i}`" 
                                    class="accordion-collapse collapse" 
                                    :aria-labelledby="`flush-heading${i}`" 
                                    data-bs-parent="#regionsAccordion"
                                >
                                    <div class="accordion-body bg-main pt-3 px-2 px-lg-3">
                                        <template v-if="region.zones">
                                            <template v-for="(zone, j) in region.zones" :key="j">
                                                <div class="w-100 bg-white mb-2 mb-lg-3 text-end">
                                                    <span class="text-uppercase px-2 px-lg-3 py-1 fw-bolder text-color-blue fs-title-4">
                                                        {{ zone.nombre }}
                                                    </span>
                                                </div>
                                                <CongresoAnteriorRegion 
                                                    :data="zone"
                                                    @show-video="onToggleModal"
                                                />
                                            </template>
                                        </template>
                                        <template v-else>
                                            <CongresoAnteriorRegion 
                                                :data="region"
                                                @show-video="onToggleModal"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="col-12 col-lg-3 d-none d-lg-block px-0 text-center">
                <img src="/assets/img/congreso-anterior.svg" 
                    class="img-fluid" 
                    style="width: 95%; margin-top: 130px;"
                />
            </div>
        </div>
    </div>
    <footerLogo />
    <BootstrapModal ref="bsModal" 
        :size="'xl'"
        :vcentered="true"
        @hidden-modal="hiddenModal"
    >
        <template v-slot:title>
            <h5 class="py-0 my-auto">
                VIDEO
            </h5>
        </template>
        <template v-slot:body>
            <div class="youtube-container">
                <iframe v-if="youtubeUrl" 
                    class="youtube-iframe rounded bg-dark"
                    :src="`${youtubeUrl}?controls=0`" 
                ></iframe>
                <div v-else class="youtube-container bg-gray-300 rounded">
                    <div class="youtube-iframe text-center d-flex flex-wrap align-items-center">
                        <img src="@/assets/no-video-1.png" 
                            class="img-fluid mx-auto"
                            style="width: 20%;"
                        >
                    </div>
                </div>
            </div>
        </template>
    </BootstrapModal>
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import axios from 'axios'

import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import BootstrapModal from '@/components/BootstrapModal.vue'
import CongresoAnteriorRegion from '@/components/CongresoAnteriorRegion.vue'

export default {
    name: 'CongresoAnterior',
    components: { mnSup, footerLogo, BootstrapModal, CongresoAnteriorRegion },
    setup() {
        const route = useRoute()
        const regions = ref([])
        const bsModal = ref()
        const youtubeUrl = ref()

        const getData = async () => {
            try {
                const resp = await axios.get('/bdd/anterior.json')
                
                if (resp.status === 200) {
                    regions.value = resp.data.regiones
                }
            } catch (error) {
                console.log(error)
                regions.value = []
            }
        }

        const onToggleModal = (url) => {
            if (typeof(url) === 'string') {
                youtubeUrl.value = url
                bsModal.value.toggle()
            }
        }

        const hiddenModal = () => {
            youtubeUrl.value = undefined
        }

        onMounted(async () => {
            document.title = route.meta.title
            await getData()

            console.log(regions.value)
        })

        return {
            regions,
            bsModal,
            youtubeUrl,

            onToggleModal,
            hiddenModal,
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-main {
    background-color: var(--color1);
}
.text-color-blue {
    color: var(--color2);
}
.accordion-button {
    background-color: white;
    border: 0.16em solid;
    border-color: black;
    &:focus {
        box-shadow: none;
        background-color: white;
        border-color: black;
    }
    @media(max-width: 576px) {
        border: 0.05em solid;
        border-color: black;
    }
    @media(max-width: 768px) {
        border: 0.1em solid;
        border-color: black;
    }
}
.accordion-button-label {
    display: inline-block;
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
    @media(max-width: 576px) {
        max-width: 180px;
        font-size: 0.8em;
    }
    @media(max-width: 768px) {
        max-width: 250px;
        font-size: 0.85em;
    }
}
.youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50.25%;
}
.youtube-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.bg-gray-300 {
    background-color: #f1f1f1;
}
.fs-title-1 {
    font-size: 2.0em;
    display: block;
    @media(max-width: 576px) {
        font-size: 1.0em;
    }
    @media(max-width: 768px) {
        font-size: 1.2em;
    }
}
.fs-title-2 {
    font-size: 1.8em;
    display: block;
    @media(max-width: 576px) {
        font-size: 0.8em;
    }
    @media(max-width: 768px) {
        font-size: 1.0em;
    }
}
.fs-title-3 {
    font-size: 1.5em;
    display: block;
    @media(max-width: 576px) {
        font-size: 0.8em;
    }
    @media(max-width: 768px) {
        font-size: 1.0em;
    }
}
.fs-title-4 {
    font-size: 1.0em;
    display: block;
    @media(max-width: 576px) {
        font-size: 0.8em;
    }
    @media(max-width: 768px) {
        font-size: 0.9em;
    }
}
</style>